import React, { useState } from 'react';
import "../pages/Location.css";

function Location() {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className='location-enquiry-container'>
            <div className='Location'>
                <h1 className="location-title">Our Location</h1>
                <p className="location-description">Visit us at Yogi Heights, Ankleshwar.</p>
                {isLoading && <div className="loading-indicator">Loading map...</div>} 
                <iframe 
                    className='MapStyle'
                    title="Google Map"
                    src="https://www.google.com/maps?q=Yogi+Heights,+Ankleshwar&hl=es;z=16&output=embed"
                    allowFullScreen=""
                    loading="lazy"
                    onLoad={handleLoad}
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

            <div className="enquiry-form">
                <h2>Enquiry Form</h2>
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <input type="tel" placeholder="Your Phone Number" />
                <textarea placeholder="Your Message"></textarea>
                <button type="submit">Submit</button>
            </div>
        </div>
    );
}

export default Location;
