import { React, useState } from 'react';
import "../pages/Overview.css";

function Overview() {
    const [showMore, setShowMore] = useState(false);

    // Toggle the state when the button is clicked
    const toggleShowMore = () => {
      setShowMore(!showMore);
    };
    return (
        <div className='overview'>
            <div className='overviewStyle'>
                <h1 className='overview-title'>Overview</h1>
                <p className='overview-text'>
                Welcome to Yogi Heights, a pioneering 13-storey high-rise in Ankleshwar, offering mid-luxury 3 and 4 BHK flats that combine comfort and urban convenience. Each spacious apartment features open layouts, premium finishes, large windows, and private balconies with stunning city views.

Yogi Heights is packed with amenities, including a modern gym, multipurpose court, landscaped gardens, and a children’s play area. The building ensures safety with a fire safety system, 24/7 security, and dedicated basement parking for all residents.        </p>

        {/* Conditionally render the additional text when 'showMore' is true */}
        {showMore && (
          <p className='overview-text'>
Though public transport is not available, Yogi Heights is conveniently located near essential facilities such as a petrol pump, highway, and a super store, ensuring easy access to your daily needs and travel routes.          </p>
        )}

        {/* Button to toggle between "Learn More" and "Show Less" */}
        <button className="overview-cta" onClick={toggleShowMore}>
          {showMore ? "Show Less" : "Show More"}
        </button>
            </div>
        </div>
    );
}

export default Overview;
