import React, { useState } from 'react';
import "../pages/Gallery.css";
import plan1 from "../images/main1.webp";
import plan2 from "../images/main3.webp";
import plan3 from "../images/main2.webp";
import plan4 from "../images/main4.webp";
import plan5 from "../images/main5.webp";
import plan6 from "../images/main6.webp";
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import FsLightbox from "fslightbox-react";

function Gallery() {
    const imagesPerPage = 3;
    const [currentPage, setCurrentPage] = useState(0);
    const [toggler, setToggler] = useState(false);
    const images = [plan1, plan2, plan3, plan4, plan5, plan6];

    // Define the maximum number of pages
    const maxPage = Math.ceil(images.length / imagesPerPage) - 1;

    const handleNext = () => {
        setCurrentPage(current => (current < maxPage ? current + 1 : current));
    };

    const handlePrevious = () => {
        setCurrentPage(current => (current > 0 ? current - 1 : current));
    };

    // Calculate the subset of images to display
    const displayedImages = images.slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage);

    return (
        <div className='Gallery'>
             <FsLightbox
				toggler={toggler}
				sources={images}
			/>
            <div className='GalleryStyle'>
                <h1 style={{ textAlign: 'center' }}>Gallery</h1>
                <div className='row2'>
                    {displayedImages.map((photo, index) => (
                        <div key={index} className='column1 polaroid1'>
                            <img className="zoom" src={photo} alt={`Gallery ${index}`} style={{ cursor: 'pointer' }} onClick={() => setToggler(!toggler)} />
                        </div>
                    ))}
                </div>
                <div className="gallery-controls">
                    <ArrowBackIos
                        onClick={handlePrevious}
                        className={`arrow-icon ${currentPage === 0 ? 'disabled' : ''}`}/>
                    <ArrowForwardIos
                        onClick={handleNext}
                        className={`arrow-icon ${currentPage === maxPage ? 'disabled' : ''}`}
                    />
                </div>
            </div>
        </div>
    );
}

export default Gallery;
