import React, {useState, useRef} from 'react';
import OfficeImg from "../images/yogi logo.webp"; // Assuming this is used for background
import "../pages/Footer.css";
import LogoImg from "../images/Yogi logo new.webp"; // Company logo
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Icons

function Footer({ siteFloorRef, amenitiesRef, galleryRef }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu visibility
    const scrollToSection = (ref, offset = 0) => {
        if (ref.current) {
          // Use scrollIntoView for smooth scrolling first
          ref.current.scrollIntoView({ behavior: 'smooth' });
      
          // Apply an artificial offset after scrolling
          setTimeout(() => {
            window.scrollBy({
              top: offset, // Apply the offset (negative or positive)
              left: 0,
              behavior: 'smooth'
            });
          }, 500); // Delay to ensure scrolling is done before applying the offset
        }
        setIsMenuOpen(false); // Close the menu
      };
      const downloadPDF = () => {
        const pdfUrl = '/brochure.pdf';  // Relative path to the PDF in the public folder
      
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', 'Yogi_Heights_Brochure.pdf');  // Filename for download
      
        // Simulate click to trigger download
        document.body.appendChild(link);
        link.click();
      
        // Cleanup
        document.body.removeChild(link);
      };
      
    return (
        <div className='footer-container'>
            {/* Background Image Overlay */}
            <div className='footer-overlay'></div>

            <div className='footerContent'>
                <div className='footer-column'>
                    <img src={LogoImg} alt="Yogi Buildcon Logo" className="footer-logo" />
                </div>

                <div className='footer-column'>
                    <h3>Contact Us</h3>
                    <p><FaMapMarkerAlt /> Yogi Heights, Near Garden City Gate, Opp. Celebration Heights, Ankleshwar.</p>
                    <p><FaEnvelope /> yogibuildcon616@gmail.com</p>
                    <p><FaPhone /> +91 92659 52894</p>
                </div>

                <div className='footer-column'>
                    <h3>About Us</h3>
                    <nav>
                    
                    <button className="about-button" onClick={() => scrollToSection(siteFloorRef)}>Site & Floor Plan</button>
                    <button className="about-button" onClick={() => scrollToSection(amenitiesRef)}>Amenities</button>
                    <button className="about-button" onClick={() => scrollToSection(galleryRef)}>Gallery</button>
                    <button className="about-button" onClick={() => downloadPDF()}>Brochure</button>
                    </nav>
                </div>

                <div className='footer-column'>
                    <h3>Follow Us</h3>
                    <div className="social-icons">
                        <a href="#"><FaFacebookF /></a>
                        <a href="#"><FaInstagram /></a>
                        <a href="#"><FaLinkedinIn /></a>
                    </div>
                </div>
            </div>

            {/* Copyright Section */}
            <div className='footer-bottom'>
                <p>&copy; 2024 Yogi Buildcon. All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;
