// FloatingContact.js
import React, { useState } from 'react';
import { FaPhoneAlt, FaTimes } from 'react-icons/fa';
import './FloatingContact.css';

function FloatingContact() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContactPanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`contact-panel ${isOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={toggleContactPanel}>
          <FaTimes />
        </button>
        <h2>Contact Us</h2>
        <p>📞 <strong>+91 92659 52894</strong></p>
        <p>Feel free to reach out for any inquiries.</p>
      </div>

      <div className="contact-icon" onClick={toggleContactPanel}>
        <FaPhoneAlt />
      </div>
    </>
  );
}

export default FloatingContact;
