import React, { useState, useRef, useEffect } from 'react';
import "../pages/Amenities.css";
import { FaSwimmingPool, FaDumbbell, FaChild, FaFireExtinguisher, FaParking, FaGamepad, FaBasketballBall, FaDoorOpen } from "react-icons/fa";

function Amenities() {
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                    }
                });
            },
            { threshold: 0.1 }
        );

        const nodes = document.querySelectorAll('.amenity-card');

        nodes.forEach((node) => observer.observe(node));

        return () => nodes.forEach((node) => observer.unobserve(node));
    }, []);

    return (
        <div className='Amenities' ref={ref}>
            <div className='AmenitiesStyle'>
                <h1 className="section-title">Amenities</h1>
                <div className='container4'>
                    <div className='column2'>
                        <div className='amenity-card'>
                            <FaParking className="check-icon" /> 
                            <div className="amenity-text">G + Basement Parking</div>
                        </div>
                        <div className='amenity-card'>
                            <FaBasketballBall className="check-icon" /> 
                            <div className="amenity-text">Multipurpose Court</div>
                        </div>
                        <div className='amenity-card'>
                            <FaGamepad className="check-icon" /> 
                            <div className="amenity-text">Indoor Games</div>
                        </div>
                        <div className='amenity-card'>
                            <FaChild className="check-icon" /> 
                            <div className="amenity-text">Children Play Area</div>
                        </div>
                    </div>
                    <div className='column2'>
                        <div className='amenity-card'>
                            <FaDumbbell className="check-icon" /> 
                            <div className="amenity-text">Gymnasium</div>
                        </div>
                        <div className='amenity-card'>
                            <FaDoorOpen className="check-icon" /> 
                            <div className="amenity-text">Entrance Foyer / Waiting Area</div>
                        </div>
                        <div className='amenity-card'>
                            <FaFireExtinguisher className="check-icon" /> 
                            <div className="amenity-text">Fire Safety</div>
                        </div>
                        <div className='amenity-card'>
                            <FaChild className="check-icon" /> 
                            <div className="amenity-text">Stage Area</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Amenities;
