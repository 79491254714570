// App.js or MainComponent.js

import React, { useState, useRef, useEffect } from 'react';
import { FaDownload, FaBars } from "react-icons/fa";
import AliceCarousel from 'react-alice-carousel';
import './MainComponent.css'; // Import general CSS here that affects overall layout
import { Helmet } from 'react-helmet';
import LogoImg from "../images/Yogi logo new.webp";  // Your logo
import Main1 from "../images/1.webp";        // Example image
import Main2 from "../images/2.webp";        // Example image
import Main3 from '../images/3.webp';
import Overview from "./Overview";
import SiteFloor from "./Site&Floor";
import Amenities from "./Amenities";
import Location from "./Location";
import LandingPage from "./Landingpage";
import Gallery from './Gallery';
import PopupBox from './popupbox';
import GalleryDemo from './GalleryDemo';
import Footer from './Footer';
import Loader from './Loader';
import FloatingContact from './FloatingContact'; // Import the floating contact component

function MainComponent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu visibility
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => setIsOpen(!isOpen);
  const overviewRef = useRef(null);
  const siteFloorRef = useRef(null);
  const amenitiesRef = useRef(null);
  const galleryRef = useRef(null);
  const locationRef = useRef(null);
  const scrollToSection = (ref, offset = 0) => {
    if (ref.current) {
      // Use scrollIntoView for smooth scrolling first
      ref.current.scrollIntoView({ behavior: 'smooth' });
  
      // Apply an artificial offset after scrolling
      setTimeout(() => {
        window.scrollBy({
          top: offset, // Apply the offset (negative or positive)
          left: 0,
          behavior: 'smooth'
        });
      }, 500); // Delay to ensure scrolling is done before applying the offset
    }
    setIsMenuOpen(false); // Close the menu
  };
  
  const items = [
    {
      imgSrc: Main1,
      text: "Welcome to Yogi Heights",
      subText: "Experience luxury and comfort like never before."
    },
    {
      imgSrc: Main2,
      text: "Modern Living",
      subText: "Designed for peace and tranquility."
    },
    {
      imgSrc: Main3,
      text: "Fulfilling dreams & desires.",
      subText: "Sets an extreme standards."
    }
    // Add more items as needed
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the dropdown menu
  };

  const downloadPDF = () => {
    const pdfUrl = '/brochure.pdf';  // Relative path to the PDF in the public folder
  
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'Yogi_Heights_Brochure.pdf');  // Filename for download
  
    // Simulate click to trigger download
    document.body.appendChild(link);
    link.click();
  
    // Cleanup
    document.body.removeChild(link);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Once the timer is done, set loading to false
    }, 3000); // Simulate a 3-second loading delay

    return () => clearTimeout(timer);
  }, []);

  const itemss = [
    <div className='carousel-item'><img src={Main1} className='sliderimg' alt="Main1" /></div>,
    <div className='carousel-item'><img src={Main2} className='sliderimg' alt="Main2" /></div>
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="app">
      <Helmet>
        <title>Yogi Heights | Modern Living</title>
        <meta name="description" content="Experience luxury living at Yogi Heights." />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-PS66GETMNV"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-PS66GETMNV');
          `}
        </script>
      </Helmet>
        <header className="header-container">
          <div className='logo-section'>
            <img src={LogoImg} alt="Yogi Heights" className='logo-image'/>
          </div>
          <div className='button-section'>
            <div className='menu-icon' onClick={toggleMenu}>
              <FaBars size={28} /> {/* Hamburger icon */}
            </div>
            <nav className={`menu ${isMenuOpen ? "menu-open" : ""}`}>
              <button className='header-button' onClick={() => scrollToSection(overviewRef)}>Overview</button>
              <button className='header-button' onClick={() => scrollToSection(siteFloorRef)}>Site & Floor Plan</button>
              <button className='header-button' onClick={() => scrollToSection(amenitiesRef)}>Amenities</button>
              <button className='header-button' onClick={() => scrollToSection(galleryRef)}>Gallery</button>
              <button className='header-button' onClick={() => scrollToSection(locationRef)}>Location</button>
              <button className='header-button' onClick={() => downloadPDF()}>
                Brochure <FaDownload className='download-icon' />
              </button>
            </nav>
          </div>
        </header>
        <div className='alice-style'>
        <AliceCarousel autoPlay autoPlayInterval={2000} infinite disableDotsControls disableButtonsControls>
          {items.map((item, index) => (
            <div key={index} className="carousel-item">
              <img src={item.imgSrc} className="sliderimg" alt={`Slide ${index}`} />
              <div className="slider-text">
                <h2>{item.text}</h2>
                <p>{item.subText}</p>
              </div>
            </div>
          ))}
        </AliceCarousel>
        </div>
        <FloatingContact />
      </div>
      <div ref={overviewRef}><Overview /></div>

      <div ref={siteFloorRef}>
        <SiteFloor />
      </div>
      <div ref={amenitiesRef}>
        <Amenities />
      </div>
      <div ref={galleryRef}>
        <Gallery />
      </div>
      <div ref={locationRef}>
        <Location />
      </div>
      <Footer  overviewRef={overviewRef} 
        siteFloorRef={siteFloorRef} 
        amenitiesRef={amenitiesRef} 
        galleryRef={galleryRef} 
        />
    </>
  );
}

export default MainComponent;
